import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import footerLogo from '../images/footerLogo.svg'
import threads from '../images/threads.png'
import instagram from '../images/instagram.png'
import twitter from '../images/twitter.png'
// import appstore from '../../assets/images/appstore.svg'
import googleplay from '../../assets/images/googleplay.svg'

const useStyles = makeStyles((theme) => ({
  footerMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#fff',
    '@media(max-width: 1280px)': {
      flexDirection: 'column-reverse',
    }
  },
  footerLogo: {
    maxHeight: '125px',
    '@media(max-width: 1280px)': {
      display: 'none',
    }
  },
  footerMenuCenter: {
    display: 'flex',
    flexDirection: 'row',
    '@media(max-width: 1280px)': {
      margin: '0%'
    },
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      margin: '5% 5%'
    }
  },
  footerMenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '0% 5%',
    '@media(max-width: 1280px)': {
      marginBottom: '2%'
    },
    '@media(max-width: 600px)': {
      marginBottom: '5%'
    }
  },
  socialMedia: {
    display: 'flex',
    padding: '0% 15%',
    alignItems: 'center',
    '@media(max-width: 1280px)': {
      padding: '3%',
      justifyContent: 'center'
    },
    '@media(max-width: 600px)': {
      padding: '5%',
      display: 'flex',
      justifyContent: 'space-evenly'
    }
  },
  titleMenuItem: {
    textTransform: 'uppercase',
    '@media(max-width: 1280px)': {
      fontSize: '1rem'
    },
  },
  titleMenuBody: {
    '@media(max-width: 1280px)': {
      fontSize: '0.8rem'
    },
  },
  socialMediaIcon: {
    margin: '0% 10%',
    '@media(max-width: 1280px)': {
      margin: '0% 3%',
    },
    '@media(max-width: 600px)': {
      margin: '0%',
    }
  }
}));

export default function OnaBetFooter() {
  const classes = useStyles();

  return (
    <div className={classes.footerMenu}>
      <img src={footerLogo} className={classes.footerLogo} />
      <div className={classes.socialMedia}>
        <a className={classes.socialMediaIcon} href="https://play.google.com/store/apps/details?id=com.roadmapps.onabet" target="_blank" rel="noopener noreferrer">
          <img src={googleplay} width="100" />
        </a>
        <a className={classes.socialMediaIcon} href="https://www.instagram.com/onabet" target="_blank" rel="noopener noreferrer">
          <img src={instagram} height="25" width="25" />
        </a>
        <a className={classes.socialMediaIcon} href="https://www.threads.net/@onabet" target="_blank" rel="noopener noreferrer">
          <img src={threads} height="25" width="25" />
        </a>
        <a className={classes.socialMediaIcon} href="https://twitter.com/onabet" target="_blank" rel="noopener noreferrer">
          <img src={twitter} height="25" width="25" />
        </a>
      </div>
    </div>
  );
}