import React, { useEffect, useState } from 'react';
import { CssBaseline, Box, Container } from '@material-ui/core';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { theme } from '../../javascript/config/themeOnaBet';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Banners from '../onabet-single-page/pages/Banners.js'
import SignIn from './SignIn.js'
import SignUp from './SignUp.js'
import Termos from '../onabet-single-page/pages/Termos.js'
import Faq from '../onabet-single-page/pages/Faq.js'
import ResetPassword from './ResetPassword.js'
import RequestPasswordReset from './RequestPasswordReset.js'
import OnaBetFooter from './OnaBetFooter.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../public/styles/main.scss";
import logo from 'images/onabet-marca.svg';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '700px',
    '@media(max-width: 868px)': {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6)
    },
  },
  banner: {
    padding: '0px',
    textAlign: '-webkit-center'
  },
  '& .MuiAppBar-positionFixed': {
    position: 'relative'
  },
  footer: {
    width: '100%',
    position: 'relative',
    bottom: 0,
    backgroundColor: '#FF283C',
    marginTop: '64px',
    '@media(max-width: 1280px)': {
      position: 'relative'
    },
    '@media(max-width: 600px)': {
      marginTop: '0px',
    }
  }
}));

export default function OnaBetPublic({ flash, global_schema: globalSchema = { current_customer: {} } }) {
  const classes = useStyles();

  useEffect(() => {
    // Atualiza o titulo do documento usando a API do browser
    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer autoClose={8000} />
        <CssBaseline />
        <AppBar style={{ position: 'relative' }}>
          <Toolbar style={{ justifyContent: 'center', color: '#fff' }}>
            <Link to="/">
              <img src={logo} alt="" title={"icone"} width="180" height="50" />
            </Link>
          </Toolbar>
        </AppBar>
        <Container className={classes.banner} component="main" maxWidth="xl">
          <Route exact path={["/", "/public", "/public/sign_in", "/public/sign_up", "/public/reset_password", "/public/request_password_reset"]}>
            <Banners banners={globalSchema.login_banners} />
          </Route>
          <Route exact path="/public/termos">
            <Termos content={globalSchema.termos_onabet} />
          </Route>
          <Route exact path="/public/faq">
            <Faq content={globalSchema.faq_onabet} />
          </Route>
        </Container>
        <Container className={classes.paper} maxWidth="xs">
          <Route exact path={["/public/sign_in"]}>
            <SignIn />
          </Route>
          <Route exact path={["/", "/public", "/public/sign_up"]}>
            <SignUp />
          </Route>
          <Route exact path="/public/reset_password">
            <ResetPassword />
          </Route>
          <Route exact path="/public/request_password_reset">
            <RequestPasswordReset />
          </Route>
        </Container>
        {navigator.userAgent != "app-ios" ? (
          <Box className={classes.footer}>
            <OnaBetFooter />
          </Box>) : ("")
        }
      </Router>
    </ThemeProvider>
  );
}
