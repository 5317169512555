import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/themeOnaBet';
import Typography from '@material-ui/core/Typography';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  main: {
    textAlign: 'center',
    padding: theme.spacing(3),
    color: 'white',
    textAlign: 'left',
    margin: '2% 23%',
    '@media(max-width: 1468px)': {
      margin: '2% 13%',
    },
    '@media(max-width: 768px)': {
      margin: '2% 0%',
    }
  },
  welcome: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '2.0rem',
    color: '#FFF',
  },
  icons: {
    fill: '#FFF',
    fontSize: '1.15em',
    marginRight: '20px',
    verticalAlign: 'top',
  },
  mainHeader: {
    padding: '20px',
    backgroundColor: '#FF283C',
    textAlign: 'left',
  },
}));

export default function Termos({content}) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Typography variant="h5" className={classes.welcome}>
            <LibraryBooksIcon className={classes.icons} />
            Termos de Uso
          </Typography>
          <br />
        </div>
        <div style={{ padding: '20px', backgroundColor: '#FF283C', width: '100%' }} dangerouslySetInnerHTML={{ __html: content.body }} />
      </div>
    </ThemeProvider>
  )
}