import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Typography, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AuthorizationClient from '../onabet-single-page/api/AuthorizationClient.js'
import LoadingButton from '../components/LoadingButton.js'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    border: '1px solid #FF283C',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#333333',
    top: 0
  },
  formHeader: {
    width: '100%',
    padding: '32px 32px 24px 32px',
    backgroundColor: '#FF283C',
    bottom: 0
  },
  h1: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '2.3rem',
  },
  submit: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '1.5rem',
    margin: theme.spacing(3, 0, 1),
  },
  submitSignUp: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '1.5rem',
    margin: theme.spacing(0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'none'
  },
  link2: {
    color: '#FFFFFF',
    textDecoration: 'none'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function sign_in(e) {
    e.preventDefault();
    document.activeElement.blur();
    
    setLoading(true);
    AuthorizationClient.signIn({email: email, password: password})
      .then(function(){
        window.location.href = '/'
      })
      .catch(function(error){
        if (error.response?.status == 401){
          setEmailError(error.response.data?.error);
        } else {
          setEmailError();
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.formHeader}>
        <Typography className={classes.h1} component="h1" variant="h5">
          LOGIN
        </Typography>
      </div>
      <form className={classes.form} onSubmit={sign_in} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          value={email}
          error={emailError}
          helperText={emailError}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          error={passwordError}
          helperText={passwordError}
          onChange={(e) => setPassword(e.target.value)}
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Lembre-se de mim"
        />

        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          ENTRAR
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submitSignUp}
        >
          <Link className={classes.link2} to="/public/sign_up">
            CADASTRE-SE
          </Link>
        </LoadingButton>
      </form>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid style={{ padding: '20px 20px 10px 20px' }} item xs>
          <Link className={classes.link} to="/public/request_password_reset" variant="body2">
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
