import React, { useState } from 'react';
import { TextField, Typography, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AuthorizationClient from '../single-page/api/AuthorizationClient'
import LoadingButton from '../components/LoadingButton.js'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    border: '1px solid #FF283C',
    padding: '32px',
    backgroundColor: '#333333',
    top: 0
  },
  formHeader: {
    width: '100%',
    padding: '32px 32px 24px 32px',
    backgroundColor: '#FF283C',
    bottom: 0
  },
  h1: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '2.0rem',
  },
  submit: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '1.5rem',
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'none'
  }
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [user, setUser] = useState({
    password: '',
    password_confirmation: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  
  function reset_password(e) {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    AuthorizationClient.resetPassword(user)
      .then(function(){
        window.location.href = '/site'
      })
      .catch(function(response){
        if (response.status == 422){
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };
  
  return (
    <div className={classes.paper}>
      <div className={classes.formHeader}>
        <Typography className={classes.h1} component="h1" variant="h5">
          RECUPERAR MINHA SENHA
        </Typography>
      </div>
      <form className={classes.form} onSubmit={reset_password} noValidate>
      {[
          { autoFocus: true, name: 'password', label: 'Senha', type: 'password', required: true },
          { autoFocus: false, name: 'password_confirmation', label: 'Confirmação da senha', type: 'password', required: true }
        ].map((field) =>
          <TextField
            required={field.required}
            key={field.name}
            id={field.name}
            fullWidth={true}
            name={field.name}
            label={field.label}
            autoComplete={field.name}
            autoFocus={field.autoFocus}
            value={user[field.name]}
            error={errors[field.name]}
            type={(field.label == 'Senha' ? showPassword : showPasswordConfirmation) ? 'text' : 'password'}
            helperText={errors[field.name] ? errors[field.name].join(', ') : ''}
            onChange={(e) => setUser({...user, [field.name]: e.target.value})}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1} onClick={field.label == 'Senha' ? togglePasswordVisibility : togglePasswordConfirmationVisibility} edge="end">
                    {field.label === 'Senha' ? (showPassword ? <Visibility /> : <VisibilityOff />) : (showPasswordConfirmation ? <Visibility /> : <VisibilityOff />)}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Redefinir senha
        </LoadingButton>
      </form>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid style={{ padding: '20px 20px 10px 20px' }} item xs>
          <Link className={classes.link} to="/public/sign_in" variant="body2">
            Entrar
          </Link>
        </Grid>
        <Grid style={{ padding: '0px 20px' }} item>
          <Link className={classes.link} to="/public/sign_up" variant="body2">
            Não possui uma conta? Cadastre-se
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}