import React, { useState } from 'react';
import { TextField, Typography, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import AuthorizationClient from '../onabet-single-page/api/AuthorizationClient'
import LoadingButton from '../components/LoadingButton.js'

const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'BebasNeue-Bold',
    alignSelf: 'start',
    fontSize: '2.3rem'
  },
  newAccountFooter: {
    fontFamily: 'BebasNeue',
    alignSelf: 'start',
    fontSize: '1.2rem'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
    border: '1px solid #FF283C',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#333333',
    top: 0
  },
  formHeader: {
    width: '100%',
    padding: '32px 32px 24px 32px',
    backgroundColor: '#FF283C',
    bottom: 0
  },
  submit: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '1.5rem',
    margin: theme.spacing(3, 0, 1),
  },
  submitSignUp: {
    fontFamily: 'BebasNeue-Bold',
    fontSize: '1.5rem',
    margin: theme.spacing(0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  link: {
    color: '#FFFFFF',
    textDecoration: 'none'
  },
  link2: {
    color: '#fff',
    textDecoration: 'none'
  }
}));

export default function SignUp() {
  const classes = useStyles();
  const [user, setUser] = useState({
    email: '',
    password: '',
    password_confirmation: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  
  function sign_up(e) {
    e.preventDefault();
    document.activeElement.blur();
    setLoading(true);
    toast.dismiss();

    AuthorizationClient.signUp(user)
      .then(function(){
        toast.info('Para dar palpites, em algumas partidas, a confirmação de e-mail se faz necessária.');
        window.location.href = '/site';
      })
      .catch(function(response){
        if (response.status == 422){
          setErrors(response.data.errors);
        } else {
          setErrors({});
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmationVisibility = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  return (
    <div className={classes.paper}>
      <div className={classes.formHeader}>
        <Typography className={classes.newAccount} component="h1" variant="h5">
          Crie sua conta
        </Typography>
        <Typography className={classes.newAccountFooter}>
          Crie sua conta para participar
        </Typography>
      </div>
      <form className={classes.form} onSubmit={sign_up} noValidate>
        <TextField
          required={true}
          key={'email'}
          id={'email'}
          name={'email'}
          label={'Email'}
          fullWidth={true}
          autoComplete={'email'}
          autoFocus={true}
          value={user['email']}
          error={errors['email'] && true}
          type={'email'}
          helperText={errors['email'] ? errors['email'].join(', ') : ''}
          onChange={(e) => setUser({ ...user, ['email']: e.target.value })}
        />
        <TextField
          required={true}
          key={'password'}
          id={'password'}
          name={'password'}
          label={'Senha'}
          fullWidth={true}
          autoComplete={'password'}
          value={user['password']}
          error={errors['password'] && true}
          type={showPassword ? 'text' : 'password'}
          helperText={errors['password'] ? errors['password'].join(', ') : ''}
          onChange={(e) => setUser({...user, ['password']: e.target.value})}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          required={true}
          key={'password_confirmation'}
          id={'password_confirmation'}
          name={'password_confirmation'}
          label={'Confirmação da senha'}
          fullWidth={true}
          autoComplete={'password_confirmation'}
          value={user['password_confirmation']}
          error={errors['password_confirmation'] && true}
          type={showPasswordConfirmation ? 'text' : 'password'}
          helperText={errors['password_confirmation'] ? errors['password_confirmation'].join(', ') : ''}
          onChange={(e) => setUser({ ...user, ['password_confirmation']: e.target.value })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={togglePasswordConfirmationVisibility} edge="end">
                  {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        
        <Typography style={{ fontSize: '14px', paddingTop: '10px' }}>
          Ao clicar, você concorda com <Link className={classes.link}>Termos e condições</Link> e <Link className={classes.link}>Política de privacidade</Link>
        </Typography>
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          CADASTRAR
        </LoadingButton>
        <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submitSignUp}
        >
          <Link className={classes.link2} to="/public/sign_in">
            LOGIN
          </Link>
        </LoadingButton>
      </form>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid style={{ padding: '20px 20px 10px 20px' }} item>
          <Link className={classes.link} to="/public/request_password_reset" variant="body2">
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
