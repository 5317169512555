import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/themeOnaBet';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  main: {
    margin: '0 auto',
    maxWidth: '800px'
  },
  bannerImg: {
    objectFit: 'cover',
    objectPosition: 'top'
  }
}));

export default function Banners({ banners }) {
  const classes = useStyles();
  const mobile = useMediaQuery('(max-width:799px)');

  function openBannerLink(link) {
    if (!link) return;
    
    window.open(link, "_blank");
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <Carousel showArrows={false} showStatus={false} interval={5000} autoPlay={true} showThumbs={false} infiniteLoop={true}>
          {banners.map(banner => {
            return (
              <div key={banner.id} onClick={() => openBannerLink(banner.link)} name="openBannerLink" style={{ cursor: banner.link ? "pointer" : "default" }}>
                <img className={classes.bannerImg} src={mobile ? banner.mobile_image?.original : banner.image?.original} />
              </div>
            )
          })}
        </Carousel>
      </div>
    </ThemeProvider>
  )
}
